import styled from 'styled-components';

export default {};

export const TableWrapper = styled.div`
  position: relative;
  margin-top: 44px;
  width: 100%;
  height: 100%;
  overflow-x: auto;

  table {
    border-spacing: 0;
    border-width: 1px;
    border-style: solid;
    border-color: transparent;
    border-image: initial;
    width: inherit;

    th {
      text-align: left;
      color: #858585;
      font-size: 16px;
      line-height: 22px;
      border-bottom: 1px solid #292929;
      white-space: nowrap;
    }

    thead {
      tr {
        th {
          font-size: 16px;
          font-weight: normal;
          line-height: 22px;
          color: #858585;
          padding: 15px 70px 15px 0px;

          &:last-child {
            padding-right: 0;
            text-align: right;
          }
        }
      }
    }

    tbody {
      tr {
        opacity: ${(props) => (props.loading ? 0 : 1)};
        td {
          font-weight: 500;
          padding: 15px 0px 15px 0px;
          border-bottom: 1px solid #292929;
          white-space: nowrap;

          @media (max-width: 960px) {
            padding: 15px 0px;
          }

          &:nth-child(5) {
            padding-right: 0;
          }
        }
      }
    }
  }
`;
