/* eslint-disable react/prop-types */

import React from 'react';
import { Container } from './styles';

const Text = ({ value, clickable }) => (
  <Container clickable={clickable}>
    {clickable ? <a href={`https://ic.rocks/principal/${value}`} target="_blank" rel="noreferrer">{value}</a> : <span>{value}</span>}
  </Container>
);

export default Text;
