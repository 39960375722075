import styled from 'styled-components';

export default {};

export const Container = styled.div`
  width: 100%;
  height: 100%;
  margin: 5vh 0vh;
  display: flex;
  align-items: center;
  justify-content: center;
  background: transparent;
  border-top: 1px solid #292929;
  img {
    width: 150px;
  }
`;
