import React from 'react';
import {
  PageWrapper,
  PageBackground,
  Banner,
  IntroWrapper,
  CardWrapper,
  BannerLogo,
  BannerHeading,
  BannerSubHeading,
  BannerWrapper,
} from './styles';
import logo from '../../assets/logo.svg';
import nft from '../../assets/nft.svg';
import token from '../../assets/token.svg';
import canister from '../../assets/canister.svg';
import dapp from '../../assets/dapp.svg';
import Card from '../../components/Card';
import SEO from '../../components/SEO';

const lists = [
  {
    icon: nft,
    name: 'NFT List',
    url: '/nfts/',
    status: 'active',
    typeformURL: 'https://dab-ooo.typeform.com/nft-list',
    docsURL: 'https://docs.dab.ooo/nft-list/getting-started/',
    released: true,
  },
  {
    icon: canister,
    name: 'Canister List',
    description: 'Canister list to associate metadata to Canister IDs.',
    url: 'https://github.com/Psychedelic/dab/blob/main/registries/canister_registry/list.md',
    typeformURL: 'https://dab-ooo.typeform.com/canister-list',
    docsURL: 'https://docs.dab.ooo/canister-list/getting-started/',
    status: 'inactive',
    released: true,
  },
  {
    icon: token,
    name: 'Token List',
    description: '',
    url: 'https://github.com/Psychedelic/dab/blob/main/registries/tokens/list.json',
    typeformURL: 'https://dab-ooo.typeform.com/token-list',
    docsURL: 'https://docs.dab.ooo/token-list/getting-started/',
    status: 'inactive',
    released: true,
  },
  {
    icon: dapp,
    name: 'Dapp List',
    url: 'https://dab-ooo.typeform.com/dapp-list',
    status: 'inactive',
    released: false,
  },
];

const LandingPage = () => (
  <PageWrapper>
    <SEO />
    <PageBackground />
    <BannerWrapper>
      <Banner>
        <IntroWrapper>
          <BannerLogo src={logo} />
          <BannerHeading>NFT, Token, Canister & Dapp Lists</BannerHeading>
          <BannerSubHeading>
            All the data an IC app needs to make seamless experiences,
            accessible directly on the IC. NFT & Token lists any app can consume
            and integrate using a universal standard wrapper, as well as
            canister and Dapp registries to surface metadata and reduce risks in
            the ecosystem.
          </BannerSubHeading>
        </IntroWrapper>
        <CardWrapper>
          {lists.map((list) => (
            <Card list={list} />
          ))}
        </CardWrapper>
      </Banner>
    </BannerWrapper>
  </PageWrapper>
);

export default LandingPage;
