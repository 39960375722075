import styled from 'styled-components';

export const PageWrapper = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  color: #ffffff;
`;

export const PageBackground = styled.div`
  position: absolute;
  width: 100%;
  height: 293px;
  left: 0px;
  top: 0px;
  background: linear-gradient(180deg, #454886 0%, rgba(21, 21, 21, 0) 100%);
`;

export const BannerWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  padding: 0px 30px;
`;

export const Banner = styled.div`
  height: 100%;
  position: relative;
  box-sizing: border-box;
`;

export const IntroWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 148px;
  height: fit-content;
`;

export const CardWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin: 70px 0px;
`;

export const BannerLogo = styled.img`
  width: 130px;
  min-height: 129px;
`;

export const BannerHeading = styled.h1`
  font-style: normal;
  font-weight: bold;
  font-size: 38px;
  line-height: 45px;
  text-align: center;
  margin: 35px 0px 0px;
`;

export const BannerSubHeading = styled.h5`
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 21px;
  text-align: center;
  color: #67687c;
  margin: 27px 0px 0px;
  max-width: 618px;
`;
