/* eslint-disable react/prop-types */

import React, { useMemo, useEffect, useState } from 'react';
import { HttpAgent } from '@dfinity/agent';
import { getAllNFTS } from '@psychedelic/dab-js';
import makeData from './makeData';
import TableLayout from '../TableLayout';
import { Image, Text } from '../TableCellData';
import { SourceVerified } from '../../Popover';
import { constructNFTTableData } from '../../../utils/tables/nftTableData';
import { CellContainer } from './styles';
import { Copy } from '../../Icons';

const NFTListTable = () => {
  const columns = useMemo(
    () => [
      {
        Header: 'Collection',
        accessor: (row) => (
          <CellContainer>
            <Image url={row.value[0].collection} size={row.value[0].size} />
            <Text value={row.value[1].collection} />
          </CellContainer>
        ),
      },
      {
        Header: 'Canister ID',
        accessor: (row) => (
          <CellContainer clickable={1} justify="space-between" maxwidth={1}>
            <Text value={row.canisterId} clickable={1} />
            <Copy canisterId={row.canisterId} />
          </CellContainer>
        ),
      },
      {
        Header: () => <SourceVerified />, // Custom header
        accessor: () => <CellContainer justify="end">-</CellContainer>,
        id: 'sourceVerified',
      },
    ],
    [],
  );

  const [loading, setLoading] = useState(false);
  const [rowData, setRowData] = useState(makeData(1));

  useEffect(() => {
    const getNFTList = async () => {
      setLoading(true);
      try {
        const agent = new HttpAgent({ host: 'https://ic0.app' });
        const nfts = await getAllNFTS(agent);
        const nftsTableData = await constructNFTTableData(nfts);
        setRowData(nftsTableData);
        setLoading(false);
        // eslint-disable-next-line no-console
        console.log(nftsTableData, 'nfts');
      } catch (e) {
        // eslint-disable-next-line no-console
        console.warn(e);
      }
    };

    getNFTList();
  }, []);

  return <TableLayout columns={columns} data={rowData} loading={loading} />;
};

export default NFTListTable;
