import React from 'react';
import ListDetails from '../../components/ListDetails';
import ComingSoon from '../../components/ComingSoon';
import SEO from '../../components/SEO';
import { ListWrapper, DetailsWrapper } from './styles';

const metaData = {
  title: 'Canister List',
  description: 'Canister list to associate metadata to Canister IDs.',
  ctaOne: 'Integrate Canister List',
  ctaTwo: 'Submit a Canister',
  ctaOneURL: 'https://docs.dab.ooo/',
  ctaTwoURL: 'https://dab-ooo.typeform.com/canister-list',
};

const CanisterList = () => (
  <ListWrapper>
    <SEO />
    <DetailsWrapper>
      <ListDetails metadata={metaData} />
      <ComingSoon />
    </DetailsWrapper>
  </ListWrapper>
);

export default CanisterList;
