import React from 'react';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import NavigationBar from './components/NavigationBar';
import CanisterList from './views/CanisterList';
import LandingPage from './views/LandingPage';
import NFTList from './views/NFTList';
import TokenList from './views/TokenList';
import DappList from './views/DappList';

function App() {
  return (
    <>
      <Router>
        <NavigationBar />
        <Switch>
          <Route path="/" exact>
            <LandingPage />
          </Route>
          <Route path="/nfts/" exact>
            <NFTList />
          </Route>
          <Route path="/canisters/" exact>
            <CanisterList />
          </Route>
          <Route path="/tokens/" exact>
            <TokenList />
          </Route>
          <Route path="/dapps/" exact>
            <DappList />
          </Route>
        </Switch>
      </Router>
    </>
  );
}

export default App;
