/* eslint-disable react/prop-types */

import React from 'react';
import { Helmet } from 'react-helmet';

const SEO = ({ metaTitle }) => (
  <Helmet>
    <title>{metaTitle || 'DAB - NFT, Token, Canister &amp; Dapp Lists on the IC'}</title>
    <meta name="viewport" content="width=device-width, initial-scale=1" />
    <meta name="theme-color" content="#000000" />
    <meta
      content="DAB - NFT, Token, Canister, &amp; Dapp Lists on the IC"
      property="og:title"
    />
    <meta
      content="An Internet Computer open internet service for canister, token, NFT, and Dapp registries &amp; verification for quality assurance and risk reduction in the ecosystem."
      property="og:description"
    />
    <meta
      content="https://storageapi2.fleek.co/fleek-team-bucket/meta.png"
      property="og:image"
    />
    <meta property="og:type" content="website" />
    <meta content="summary_large_image" name="twitter:card" />
  </Helmet>
);

export default SEO;
