import React from 'react';
import ListDetails from '../../components/ListDetails';
import SEO from '../../components/SEO';
import NFTListTable from '../../components/Tables/NFTListTable';
import {
  NFTListWrapper,
  PageBackground,
  NFTDetailsWrapper,
  NFTTableWrapper,
} from './styles';

const metaData = {
  title: 'NFT List',
  ctaOne: 'Integrate List',
  ctaTwo: 'Submit NFT',
  ctaOneURL: 'https://docs.dab.ooo/nft-list/getting-started/',
  ctaTwoURL: 'https://dab-ooo.typeform.com/nft-list',
};

const NFTList = () => (
  <NFTListWrapper>
    <SEO metaTitle="NFT List - DAB" />
    <PageBackground />
    <NFTDetailsWrapper>
      <ListDetails metadata={metaData} />
      <NFTTableWrapper>
        <NFTListTable />
      </NFTTableWrapper>
    </NFTDetailsWrapper>
  </NFTListWrapper>
);

export default NFTList;
