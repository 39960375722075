import React from 'react';
import PropTypes from 'prop-types';
import {
  MetaDatas,
  MetaDataWrapper,
  MetaDataHeading,
  MetaDataPrompts,
  ButtonListWrapper,
  ButtonContainer,
} from './styles';
import Button from '../Button';
import { SearchCominSoon } from '../Popover';
import Search from '../Search';

const ListDetails = ({ metadata }) => (
  <MetaDatas>
    <MetaDataWrapper>
      <MetaDataHeading>{metadata.title}</MetaDataHeading>
    </MetaDataWrapper>
    <MetaDataPrompts>
      <SearchCominSoon><Search /></SearchCominSoon>
      <ButtonListWrapper>
        <ButtonContainer>
          <Button
            content={metadata.ctaOne}
            url={metadata.ctaOneURL}
            primary={false}
          />
        </ButtonContainer>
        <ButtonContainer>
          <Button content={metadata.ctaTwo} url={metadata.ctaTwoURL} primary />
        </ButtonContainer>
      </ButtonListWrapper>
    </MetaDataPrompts>
  </MetaDatas>
);

ListDetails.propTypes = {
  // eslint-disable-next-line
  metadata: PropTypes.object.isRequired,
};

export default ListDetails;
