import React from 'react';
import Tippy from '@tippyjs/react';
import 'tippy.js/dist/tippy.css'; // optional
import 'tippy.js/themes/light.css';
import 'tippy.js/animations/perspective.css';
import {
  Container,
  TableHeaderData,
  TooltipContainer,
  Header,
  Description,
} from './styles';

const Tooltip = () => (
  <TooltipContainer>
    <Header>What is Source Verified?</Header>
    <Description>
      Source code verification provides improved transparency and safety for
      users interacting with canisters by ensuring the actual canister code
      running on the Internet Computer matches the open source code in the
      repository. <a href="https://covercode.ooo/" target="_blank" rel="noreferrer">Learn More.</a>
    </Description>
  </TooltipContainer>
);

const SourceVerified = () => (
  <Container>
    <Tippy
      placement="bottom-end"
      animation="perspective-subtle"
      interactive
      content={<Tooltip />}
    >
      <TableHeaderData>Source Verified</TableHeaderData>
    </Tippy>
  </Container>
);

export default SourceVerified;
