import styled from 'styled-components';

/* eslint-disable import/prefer-default-export */
export const BannerRedirectBtn = styled.a`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 8px;
  background: linear-gradient(180deg, #8f94fb 0%, #545acd 100%);
  border-radius: 12px;
  transition: all 200ms ease;
  color: #fff;
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  text-align: center;
  width: 100%;

  &::after {
    content: '';
    border-radius: 12px;
    width: calc(100% - 4px);
    height: 88%;
    background: ${(props) => (props.primary ? '' : '#151515')};
    position: absolute;
    left: 2px;
    top: 2px;
  }

  &:hover {
    cursor: pointer;
    /* transform: scale(1.05); */
  }
`;

export const ButtonText = styled.span`
  position: relative;
  z-index: 1;
`;
