import React from 'react';
import ListDetails from '../../components/ListDetails';
import ComingSoon from '../../components/ComingSoon';
import SEO from '../../components/SEO';
import { ListWrapper, DetailsWrapper } from './styles';

const metaData = {
  title: 'Token List',
  description: 'Token list any app can use to auto-surface tokens.',
  ctaOne: 'Integrate DAB',
  ctaTwo: 'Submit a Token',
  ctaOneURL: 'https://docs.dab.ooo/',
  ctaTwoURL: 'https://dab-ooo.typeform.com/token-list',
};

const TokenList = () => (
  <ListWrapper>
    <SEO />
    <DetailsWrapper>
      <ListDetails metadata={metaData} />
      <ComingSoon />
    </DetailsWrapper>
  </ListWrapper>
);

export default TokenList;
