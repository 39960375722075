/* eslint-disable react/prop-types */

import React from 'react';
import logo from '../../assets/logo.svg';
import { Container } from './styles';

const Loader = ({ alt }) => (
  <Container>
    <img src={logo} alt={alt || 'Loading table data'} />
  </Container>
);

export default Loader;
