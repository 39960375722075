import styled from 'styled-components';

export default {};

export const CellContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: ${(props) => (props.justify ? props.justify : '')};
  max-width: ${(props) => (props.maxwidth ? '280px' : '')};
`;
