import React from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';

const RouterLink = ({ to, children }) => <Link to={to}>{children}</Link>;

RouterLink.propTypes = {
  to: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
};

export default RouterLink;
