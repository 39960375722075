import styled from 'styled-components';

export const ListWrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: 100%;
  background: linear-gradient(180deg, #4f55c7 0%, rgba(21, 21, 21, 0) 40%);
  color: #ffffff;

  @media (max-width: 960px) {
    padding: 20px;
  }
`;

export const DetailsWrapper = styled.div`
  position: relative;
  top: 10rem;
  max-width: 1110px;
  width: 100%;
`;

export const TableWrapper = styled.div`
  position: relative;
`;
