import React from 'react';
import PropTypes from 'prop-types';
import { BannerRedirectBtn, ButtonText } from './styles';

const Button = ({
  content,
  url,
  status,
  primary,
}) => (
  <BannerRedirectBtn
    href={url}
    target="_blank"
    primary={primary}
    status={status}
  >
    <ButtonText>{content}</ButtonText>
  </BannerRedirectBtn>
);

Button.defaultProps = {
  primary: PropTypes.bool,
};

Button.propTypes = {
  content: PropTypes.string.isRequired,
  url: PropTypes.string.isRequired,
  status: PropTypes.string.isRequired,
  primary: PropTypes.bool,
};

export default Button;
