import styled from 'styled-components';

export default {};

export const Container = styled.div`
  width: 16px;
  height: 16px;
  svg {
    width: 16px;
    height: 16px;
    path {
      fill: #ffffff;
      &:hover {
        fill: #7f86ff;
      }
    }
  }
  &:hover {
    cursor: pointer;
    path {
      fill: #7f86ff;
    }
  }
`;
