import styled from 'styled-components';

export const Container = styled.div`
  .tippy-box {
    background: #212236;
    border-radius: 10px;
    width: 175px !important;
    border: 1px solid #7f86ff;
  }
  .tippy-content {
    padding: 0px;
  }
  .tippy-box[data-placement^='bottom'] > .tippy-arrow:before {
    border-bottom-color: #212236;
  }
  .tippy-box[data-placement^='bottom'] > .tippy-arrow:after {
    content: '';
    position: absolute;
    border-color: transparent;
    border-style: solid;
    border-width: 0px 8px 8px;
    border-bottom-color: #7f86ff;
    top: -8px;
    left: -1px;
    border-width: 0 9px 8px;
    z-index: -1;
    transform-origin: center bottom;
  }
`;

export const TableHeaderData = styled.span`
  :hover {
    color: #7f86ff;
    cursor: pointer;
  }
`;

export const TooltipContainer = styled.div`
  padding: 8px 14px;
`;

export const Description = styled.div`
  font-style: normal;
  font-weight: normal;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 22px;
  color: #ffffff;
  text-align: center;
`;
