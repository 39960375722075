import styled from 'styled-components';

export const CardContent = styled.div`
  position: relative;
  width: 282px;
  height: 260px;
  padding: 34px 28px 25px 28px;
  margin: 15px;
  background: #151515;
  border: 1px solid #343434;
  box-sizing: border-box;
  box-shadow: 0px 4px 14px rgb(0 0 0 / 45%);
  border-radius: 15px;
`;

export const CardContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;
`;

export const CardIcon = styled.img`
  height: 48px;
`;

export const CardContentTitle = styled.h4`
  color: #fff;
  font-style: normal;
  font-weight: bold;
  font-size: 22px;
  line-height: 26px;
  margin: ${(props) => (!props.released ? '20px 0px 47px' : '20px 0px')};
`;

export const CardLabel = styled.div`
  position: absolute;
  width: 98px;
  height: 27px;
  top: 15px;
  background: rgba(139, 144, 248, 0.3);
  border-radius: 6px 0px 0px 6px;
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 14px;
  text-align: center;
  color: #8E93FA;
  right: 0px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const ButtonListWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

export const ButtonContainer = styled.div`
  width: 225px;
  margin-bottom: 17px;
`;

export const ButtonFlexContainer = styled.div`
  display: flex;
  width: 225px;
  a {
    &:first-child {
      margin-right: 9px;
    }
  }
`;
