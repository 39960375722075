/* eslint-disable react/prop-types */

import React from 'react';
import { Container } from './styles';

const Image = ({ url, size }) => (
  <Container size={size}>
    <img src={url} alt="logo" />
  </Container>
);

export default Image;
