import React from 'react';
import PropTypes from 'prop-types';
import Tippy from '@tippyjs/react';
import 'tippy.js/dist/tippy.css'; // optional
import 'tippy.js/themes/light.css';
import 'tippy.js/animations/perspective.css';
import {
  Container,
  TooltipContainer,
  Description,
} from './styles';

const Tooltip = () => (
  <TooltipContainer>
    <Description>Search coming soon!</Description>
  </TooltipContainer>
);

const SearchCominSoon = ({ children }) => (
  <Container>
    <Tippy
      placement="bottom"
      animation="perspective-subtle"
      interactive
      content={<Tooltip />}
    >
      <div>{children}</div>
    </Tippy>
  </Container>
);

SearchCominSoon.propTypes = {
  children: PropTypes.node.isRequired,
};

export default SearchCominSoon;
