import React from 'react';
import PropTypes from 'prop-types';
import Button from '../Button';
import RouterLink from '../RouterLink';
import {
  CardContent,
  CardContentWrapper,
  CardContentTitle,
  CardIcon,
  CardLabel,
  ButtonListWrapper,
  ButtonContainer,
  ButtonFlexContainer,
} from './styles';

const Card = ({ list }) => (
  <CardContent>
    {!list.released && <CardLabel>Coming Soon!</CardLabel>}
    <CardContentWrapper>
      <CardIcon src={list.icon} />
      <CardContentTitle released={list.released ? 1 : 0}>{list.name}</CardContentTitle>
      {!list.released ? (
        <Button
          content="Submit Early"
          status={list.status}
          url={list.url}
          primary={0}
        />
      ) : (
        <ButtonListWrapper>
          {list.status === 'active' ? (
            <RouterLink to={list.url}>
              <ButtonContainer>
                <Button content="View" status={list.status} />
              </ButtonContainer>
            </RouterLink>
          ) : (
            <ButtonContainer>
              <Button content="View" status={list.status} url={list.url} />
            </ButtonContainer>
          )}
          <ButtonFlexContainer>
            <Button
              content="Submit"
              status="active"
              url={list.typeformURL}
              primary={0}
            />
            <Button
              content="Integrate"
              status="active"
              url={list.docsURL}
              primary={0}
            />
          </ButtonFlexContainer>
        </ButtonListWrapper>
      )}
    </CardContentWrapper>
  </CardContent>
);

Card.propTypes = {
  // eslint-disable-next-line
  list: PropTypes.object.isRequired,
};

export default Card;
