import styled from 'styled-components';

export const NFTListWrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: 100%;
  color: #ffffff;

  @media (max-width: 960px) {
    padding: 20px;
  }
`;

export const PageBackground = styled.div`
  position: absolute;
  width: 100%;
  height: 293px;
  left: 0px;
  top: 0px;
  background: linear-gradient(180deg, #454886 0%, rgba(21, 21, 21, 0) 100%);
`;

export const NFTDetailsWrapper = styled.div`
  position: relative;
  margin-top: 118px;
  width: 100%;
  max-width: 957px;
`;

export const NFTTableWrapper = styled.div`
  position: relative;
`;
