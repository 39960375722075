import styled from 'styled-components';

export default {};

export const Container = styled.div`
  width: ${(props) => (props.size && props.size === 'small' ? '20px' : '25px')};
  height: ${(props) => (props.size && props.size === 'small' ? '20px' : '25px')};
  background: #292929;
  border-radius: 6px;
  margin: 0px 10px 0px 0px;
  display: flex;
  align-items: center;
  justify-content: center;
  img {
    max-width: 25px;
    background: #292929;
    border-radius: 6px;
  }
`;
