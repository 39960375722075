import styled from 'styled-components';

/* eslint-disable import/prefer-default-export */
export const SearchWrapper = styled.div`
  position: relative;
  opacity: 0.5;
  margin: 20px 0;

  input {
    background-color: #151515;
    color: #67687c;
    border: 1px solid #151515;
    border-radius: 12px;
    padding: 8px 40px;
    width: 340px;
    height: 35px;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 19px;
    pointer-events: none;

    @media (max-width: 960px) {
      width: 100%
    }
  }

  input::placeholder {
    font-size: 14px;
  }

  input:focus {
    outline: 0;
  }

  img {
    position: absolute;
    left: 15px;
    top: 10px;
  }

  img::before {
    content: '';
    display: block;
    position: relative;
    top: 0em;
    left: -0.3em;
    width: 0.5em;
    height: 0.5em;
    border-radius: 100%;
    border: 0.125em solid $input-text-color;
    background-color: $input-bg-color;

    transition: transform 0.5s $animation-bounce;
  }
`;
