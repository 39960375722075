import React, { useState, useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';
import Tippy from '@tippyjs/react';
import {
  NavigationWrapper,
  NavigationRoutes,
  NavigationLogo,
  NavigationSocials,
  NavigationSocialsWrapper,
  NavigationSocialsIcon,
  TippyWrapper,
  DropDownButtonWrapper,
  DropDownButtonContent,
  DropDownButtonContentWrapper,
  ButtonWrapper,
  NavigationMobileOpenMenuIcon,
  NavigationMobileCloseMenuIcon,
  NavigationMobileMenuWrapper,
  NavigationMobileMenu,
  RedirectLink,
} from './styles';
import Button from '../Button';
import closeMenu from '../../assets/close-menu.svg';
import hamburger from '../../assets/hamburger.svg';
import logo from '../../assets/full-logo.svg';
import discord from '../../assets/discord.svg';
import twitter from '../../assets/twitter.svg';
import github from '../../assets/github.svg';
import arrowdown from '../../assets/arrowdown.svg';
import check from '../../assets/check.svg';

const navcontentlist = [
  {
    name: 'NFT List',
    status: 'active',
    path: '/nfts/',
    released: true,
  },
  {
    name: 'Canister List',
    status: 'inactive',
    path: 'https://github.com/Psychedelic/dab/blob/main/registries/canister_registry/list.md',
    released: true,
  },
  {
    name: 'Token List',
    status: 'inactive',
    path: 'https://github.com/Psychedelic/dab/blob/main/registries/tokens/list.json',
    released: true,
  },
  {
    name: 'Dapp List',
    status: 'inactive',
    released: false,
  },
];

const NavigationBar = () => {
  const [menuOpen, setMenuOpen] = useState(false);
  const [dropdownText, setDropdownText] = useState('Choose List');
  const location = useLocation();

  useEffect(() => {
    if (location.pathname !== '/') {
      setDropdownText(`${location.pathname.toUpperCase().slice(1, -2)} List`);
    } else {
      setDropdownText('Choose List');
    }
  }, [location.pathname]);

  const handleMenu = () => {
    setMenuOpen(!menuOpen);
  };

  return (
    <div>
      <NavigationWrapper>
        <NavigationRoutes>
          <Link to="/">
            <NavigationLogo src={logo} />
          </Link>
          <TippyWrapper>
            <Tippy
              placement="bottom-start"
              animation="perspective"
              theme="translucent"
              interactive
              arrow={false}
              content={
                (
                  <DropDownButtonContent>
                    {navcontentlist.map((listitem) => (
                      <DropDownButtonContentWrapper
                        released={listitem.released}
                        onClick={() => setDropdownText(listitem.status === 'active' ? listitem.name : dropdownText)}
                      >
                        {listitem.status === 'active' ? (
                          <Link
                            to={listitem.path ? listitem.path : ''}
                            onClick={(event) => !listitem.path && event.preventDefault()}
                          >
                            <p>{listitem.name}</p>
                          </Link>
                        ) : (
                          <a
                            href={listitem.path ? listitem.path : ''}
                            target="_blank"
                            rel="noreferrer"
                            onClick={(event) => !listitem.path && event.preventDefault()}
                          >
                            <p>{listitem.name}</p>
                          </a>
                        )}
                        <span>
                          {`${location.pathname
                            .toUpperCase()
                            .slice(1, -2)} List` === listitem.name ? (
                              <img src={check} alt="check" />
                            ) : (
                              ''
                            )}
                          <p>{listitem.status !== 'active' && !listitem.released && 'Coming Soon!'}</p>
                        </span>
                      </DropDownButtonContentWrapper>
                    ))}
                  </DropDownButtonContent>
                )
              }
            >
              <DropDownButtonWrapper>
                <p>{dropdownText}</p>
                <img src={arrowdown} alt="arrow-down" />
              </DropDownButtonWrapper>
            </Tippy>
          </TippyWrapper>
        </NavigationRoutes>
        <NavigationSocials>
          <NavigationSocialsWrapper>
            <a
              href="https://github.com/psychedelic/dab"
              target="_blank"
              rel="noreferrer"
            >
              <NavigationSocialsIcon src={github} />
            </a>
            <a
              href="https://discord.gg/yVEcEzmrgm"
              target="_blank"
              rel="noreferrer"
            >
              <NavigationSocialsIcon src={discord} />
            </a>
            <a
              href="https://twitter.com/dab_ooo"
              target="_blank"
              rel="noreferrer"
            >
              <NavigationSocialsIcon src={twitter} />
            </a>
          </NavigationSocialsWrapper>
          <RedirectLink
            href="https://docs.dab.ooo/resources/what-is-dab/#why-use-dab-it-provides-two-clear-benefits"
            target="_blank"
            rel="noreferrer"
          >
            Why DAB ?
          </RedirectLink>
          <ButtonWrapper>
            <Button content="Docs" url="https://docs.dab.ooo/" />
          </ButtonWrapper>
          {!menuOpen ? (
            <NavigationMobileOpenMenuIcon
              src={hamburger}
              onClick={handleMenu}
            />
          ) : (
            <NavigationMobileCloseMenuIcon
              src={closeMenu}
              onClick={handleMenu}
            />
          )}
        </NavigationSocials>
      </NavigationWrapper>
      <NavigationMobileMenuWrapper menuOpen={menuOpen}>
        <NavigationMobileMenu>
          <ul>
            {navcontentlist.map((listitem) => (
              <DropDownButtonContentWrapper released={listitem.released}>
                {listitem.status === 'active' ? (
                  <Link
                    to={listitem.path ? listitem.path : ''}
                    onClick={(event) => {
                      if (!listitem.path) event.preventDefault();
                      setMenuOpen(!menuOpen);
                    }}
                  >
                    <p>{listitem.name}</p>
                  </Link>
                ) : (
                  <a
                    href={listitem.path ? listitem.path : ''}
                    target="_blank"
                    rel="noreferrer"
                    onClick={(event) => {
                      if (!listitem.path) event.preventDefault();
                      setMenuOpen(!menuOpen);
                    }}
                  >
                    <p>{listitem.name}</p>
                  </a>
                )}
                <span>
                  {`${location.pathname
                    .toUpperCase()
                    .slice(1, -2)} List` === listitem.name ? (
                      <img src={check} alt="arrow-down" style={{ width: '15px' }} />
                    ) : (
                      ''
                    )}
                  {listitem.status !== 'active' && !listitem.released && <p>Coming Soon!</p>}
                </span>
              </DropDownButtonContentWrapper>
            ))}
          </ul>
          <ul>
            <NavigationSocialsIcon src={github} />
            <NavigationSocialsIcon src={discord} />
            <NavigationSocialsIcon src={twitter} />
          </ul>
        </NavigationMobileMenu>
      </NavigationMobileMenuWrapper>
    </div>
  );
};

export default NavigationBar;
