import styled from 'styled-components';

export const NavigationWrapper = styled.div`
  position: fixed;
  z-index: 10;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: 15px 30px;
  background: #212236;

  @media (max-width: 960px) {
    z-index: 5;
  }
`;

export const NavigationRoutes = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const NavigationLogo = styled.img`
  height: 37px;
`;

export const TippyWrapper = styled.div`
  .tippy-box {
    background: none;
  }

  .tippy-content {
    padding: 0px;
    position: relative;
  }

  @media (max-width: 960px) {
    display: none;
  }
`;

export const DropDownButtonWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  border: 1px solid #ffffff;
  border-radius: 12px;
  padding: 8px 15px;
  width: 175px;
  margin-left: 50px;
  cursor: pointer;

  :hover {
    border: 1px solid #7F86FF;
  }

  p {
    margin: 0;
    color: white;
    font-weight: 500;
    font-size: 16px;
    line-height: 19px;
  }
`;

export const DropDownButtonContent = styled.div`
  background: #212236;
  border: 1px solid #7F86FF;
  border-radius: 10px;
  padding: 20px 16px;
  width: 275px;

  div {
    display: flex;
    color: white;
  }
`;

export const DropDownButtonContentWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 17px;

  p {
    color: ${(props) => (props.released ? 'white' : '#67687C')};
    cursor: ${(props) => (props.released ? 'pointer' : 'not-allowed')};
    font-style: normal;
    font-weight: ${(props) => (props.released ? 500 : '')};
    font-size: 16px;
    line-height: 19px;
  }

  &:first-child {
    margin-top: 0px;
  }
`;

export const NavigationSocials = styled.ul`
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  a {
    margin-right: 20px;
  }

  @media (max-width: 440px) {
    a {
      margin-right: 10px;
    }
  }
`;

export const NavigationSocialsWrapper = styled.div`
  @media (max-width: 960px) {
    display: none;
  }
`;

export const NavigationSocialsIcon = styled.img`
  height: 18px;
  cursor: pointer;
`;

export const ButtonWrapper = styled.div`
  width: 69px;
  margin-left: 5px;
`;

export const NavigationMobileOpenMenuIcon = styled.img`
  display: none;
  cursor: pointer;
  margin-left: 20px;

  @media (max-width: 960px) {
    display: block;
  }
`;

export const NavigationMobileCloseMenuIcon = styled.img`
  display: none;
  margin-left: 20px;

  @media (max-width: 960px) {
    display: block;
    color: white;
    cursor: pointer;
    width: 30px;
  }
`;

export const NavigationMobileMenuWrapper = styled.div`
  display: none;

  @media (max-width: 960px) {
    display: flex;
    justify-content: space-between;
    transform: ${({ menuOpen }) => (menuOpen ? 'translateX(0)' : 'translateX(-100%)')};
    transition: transform 0.3s ease-in-out;
    align-items: flex-start;
    position: fixed;
    background: black;
    z-index: 2;
    color: white;
    padding: 20px 30px 80px;
    height: 100%;
    width: 100%;
    right: 0;
    top: 2.5rem;
  }
`;

export const NavigationMobileMenu = styled.div`
  display: none;

  @media (max-width: 960px) {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
    width: 100%;

    ul {
      &:nth-child(1) {
        margin-top: 2.5rem;
      }

      li {
        list-style: none;
        padding: 0 20px;
        margin-bottom: 1.5rem;
        cursor: pointer;
        font-size: 20px;
        line-height: 24px;
        cursor: pointer;
      }
    }

    img {
      width: 40px;
      cursor: pointer;
      &:nth-child(2) {
        max-width: 40px;
      }
    }
  }
`;

export const RedirectLink = styled.a`
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  color: #FFFFFF;
  text-align: center;
  margin-left: 5px;
  @media (max-width: 390px) {
    display: none;
  }
`;
