import React from 'react';
import { SearchWrapper } from './styles';
import search from '../../assets/search.svg';

const Search = () => (
  <SearchWrapper>
    <input placeholder="Search" type="text" />
    <img src={search} alt="search" />
  </SearchWrapper>
);

export default Search;
