import styled, { keyframes } from 'styled-components';

export default {};

// Keyframes to animate
const pulse = keyframes`
  0% {
    transform: scale(1);
  }
  60% {
    transform: scale(1.1);
  }
  100% {
    transform: scale(1);
  }
`;

export const Container = styled.div`
  width: 100%;
  height: 100%;
  margin: 2vh 0vh;
  display: flex;
  align-items: center;
  justify-content: center;
  background: transparent;
  img {
    height: 100px;
    animation: ${pulse} 800ms linear infinite;
  }
`;
