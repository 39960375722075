import dayjs from 'dayjs';

export default {};

const formatTimestamp = (timestamp) => {
  let formattedTimestamp = '-';
  if (timestamp) {
    // eslint-disable-next-line no-useless-escape
    const timestampInMilliseconds = timestamp.toString().replace(/[\[\]&]+/g, '').substring(0, 10);
    if (timestampInMilliseconds && typeof timestampInMilliseconds === 'string') {
      formattedTimestamp = dayjs.unix(Number(timestampInMilliseconds)).format('DD/MM/YYYY');
    }
  }
  return formattedTimestamp;
};

export const constructNFTTableData = async (rawData) => {
  const tableData = [];
  // eslint-disable-next-line no-plusplus
  for (let index = 0; index < rawData.length; index++) {
    const rowData = {
      value: [
        {
          collection: rawData[index]?.icon,
          type: 'image',
          size: 'medium',
        },
        {
          collection: rawData[index]?.name,
          type: 'text',
        },
      ],
      canisterId: rawData[index]?.principal_id.toText(),
      standard: rawData[index]?.standard,
      modifiedDate: formatTimestamp(rawData[index]?.timestamp),
    };
    tableData.push(rowData);
  }
  return tableData;
};
