import styled from 'styled-components';

export default {};

export const Container = styled.div`
  width: 40px;
  height: 40px;
  img {
    height: 100%;
  }
`;
