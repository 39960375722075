/* eslint-disable react/prop-types */

import React from 'react';
import comingsoonLogo from '../../assets/coming-soon.svg';
import { Container } from './styles';

const ComingSoon = ({ alt }) => (
  <Container>
    <img src={comingsoonLogo} alt={alt || 'Coming soon'} />
  </Container>
);

export default ComingSoon;
