import styled from 'styled-components';

export default {};

export const Container = styled.div`
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 22px;
  color: #ffffff;
  margin: 0px 10px 0px 0px;

  a,
  span {
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 22px;
    color: #ffffff;
    &:hover {
      cursor: ${(props) => (props.clickable ? 'pointer' : '')};
      color: ${(props) => (props.clickable ? '#7F86FF' : '')};
    }
  }
`;
