import styled from 'styled-components';

export const MetaDatas = styled.div`
  position: relative;
  color: #ffffff;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;

  @media (max-width: 960px) {
    display: block;
  }
`;

export const MetaDataWrapper = styled.div`
  margin-right: 10px;

  @media (max-width: 960px) {
    margin-right: 0px;
  }
`;

export const MetaDataHeading = styled.h1`
  font-style: normal;
  font-weight: 500;
  font-size: 36px;
  line-height: 43px;
`;

export const MetaDataPrompts = styled.div`
  display: flex;
  align-items: center;

  // @media (max-width: 960px) {
  //   flex-wrap: wrap;
  // }

  @media (max-width: 960px) {
    display: block;
  }
`;

export const ButtonListWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: flex-start;

  @media (max-width: 960px) {
    justify-content: flex-start;
  }
`;

export const ButtonContainer = styled.div`
  width: 135px;
  &:first-child {
    margin: 0px 10px;
  }
  @media (max-width: 960px) {
    &:first-child {
      margin-left: 0px;
    }
  }
`;
