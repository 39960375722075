/* eslint-disable */

import namor from 'namor';

const range = (len) => {
  const arr = [];
  for (let i = 0; i < len; i++) {
    arr.push(i);
  }
  return arr;
};

const newNFT = () => {
  const statusChance = Math.random();
  return {
    value: [
      {
        collection: 'https://storageapi2.fleek.co/fleek-team-bucket/logos/emWjzvJf_400x400.jpg',
        type: 'image',
        size: 'medium',
      },
      {
        collection: namor.generate({ words: 2, saltLength: 10 }),
        type: 'text',
      }
    ],
    canisterId: namor.generate({ words: 1, saltLength: 5 }),
    standard: 'EXT',
    modifiedDate: Math.floor(100000 * 100),
    verified: 'Verified',
  };
};

export default function makeData(...lens) {
  const makeDataLevel = (depth = 0) => {
    const len = lens[depth];
    return range(len).map((d) => {
      return {
        ...newNFT(),
        subRows: lens[depth + 1] ? makeDataLevel(depth + 1) : undefined,
      };
    });
  };

  return makeDataLevel();
}
