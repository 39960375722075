import React from 'react';
import ListDetails from '../../components/ListDetails';
import SEO from '../../components/SEO';
import ComingSoon from '../../components/ComingSoon';
import { ListWrapper, DetailsWrapper } from './styles';

const metaData = {
  title: 'Dapp List',
  description: 'Dapp registry to verify and auto-surface Dapps in UIs.',
  ctaOne: 'Integrate DAB',
  ctaTwo: 'Submit a Dapp',
  ctaOneURL: 'https://docs.dab.ooo/',
  ctaTwoURL: 'https://dab-ooo.typeform.com/dapp-list',
};

const DappList = () => (
  <ListWrapper>
    <SEO />
    <DetailsWrapper>
      <ListDetails metadata={metaData} />
      <ComingSoon />
    </DetailsWrapper>
  </ListWrapper>
);

export default DappList;
